import React from 'react';

const Footer = () => {
    return (
      <div className="App">
        
      </div>
    );
  }
  
  export default Footer;